import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { MainBanner } from "../components/banners/main-banner"
import ImageGrid from "../components/image-grid"

import { MainLayout } from "../components/layouts/layout"
import MainSection from "../components/sections/main-section"
import TextBlock from "../components/textblocks/text-block"

import TwentyTwenty from "react-twentytwenty"

import { RiCodeFill } from "react-icons/ri"

import Img from "gatsby-image"
import SEO from "../components/seo"

export default function Home({ data }) {
  return (
    <MainLayout activeNav="/">
      <SEO title="Kvalitets køkkener med omtanke" />
      <MainBanner
        image={data.banner.childImageSharp.base64.base64}
        title="Jysk køkkenfornyelse"
        description="Fornyelse med omtanke"
      />
      <Container fluid>
        <MainSection>
          <Container>
            <Row>
              <Col xs={12} md={6} className="mb-4 mb-md-0">
                <TextBlock
                  title="KØKKENFORNYELSE MED OMTANKE!"
                  tag="Læs mere om konceptet"
                  linkTo={[{ link: "/products", buttonText: "Fornyelse" }]}
                >
                  <p>
                    Vi har alle et ansvar og skal bidrage til en bedre verden.
                    Derfor giver det også god mening, at bibeholde de
                    eksisterende skabe, når du skal have fornyet dit køkken.
                  </p>
                  <p>
                    Ved at bevare dine eksisterende skabe, er du med til at
                    reducere resursespild.
                  </p>
                  <p>
                    Hvorfor tage et godt og funktionelt skab ud af køkkenet, for
                    derefter at placere et nyt skab - på samme sted.
                  </p>
                  <p>
                    Når du vælger køkkenfornyelse hos jysk køkkenfornyelse, kan
                    du samtidig være sikker på at alle vore bordplader opfylder
                    en række kriterier fra frivillige mærkningsordninger som den
                    Nordiske "Svane mærke", EU-Ecolabel : "Blomsten" samt den
                    internationale tyske "Blaue Engel" - massivbordplader er FSC
                    Certificeret (miljømærket for ansvarligt skovbrug).
                  </p>
                </TextBlock>
              </Col>
              <Col xs={12} md={6}>
                <ImageGrid
                  imageOne={data.imgOne.childImageSharp.fluid}
                  imageTwo={data.imgTwo.childImageSharp.fluid}
                />
              </Col>
            </Row>
          </Container>
        </MainSection>
        <MainSection backgroundColor="rgb(51, 51, 51)">
          <Container>
            <Row>
              <Col>
                <div
                  style={{
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <h2 className="text-center">
                    KVALITET - TRYGHED - KOMPETANCE
                  </h2>
                  <p className="green text-center">
                    Sammen skaber vi rammerne omkring din fornyelse
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </MainSection>
        <MainSection>
          <Container>
            <Row>
              <Col xs={12} md={6} className="mb-4 mb-md-0">
                <TwentyTwenty
                  className="w-100"
                  left={<Img fixed={data.imgOne.childImageSharp.fixed} />}
                  right={<Img fixed={data.imgOne.childImageSharp.fixed} />}
                  slider={
                    <div className="slider">
                      <span className="d-flex align-items-center justify-content-center">
                        <RiCodeFill />
                      </span>
                    </div>
                  }
                />
              </Col>
              <Col xs={12} md={6}>
                <TextBlock
                  title="KØKKENFORNYELSE NOGET FOR MIG?"
                  tag="Så kontakt os her"
                  linkTo={[{ link: "/kontakt-os/", buttonText: "kontakt" }]}
                  light
                >
                  <p>
                    Oftest vælges en fornyelse, fordi tiden er løbet fra det
                    gamle køkken. Køkkenet har tjent os godt, men har fået
                    patina og brugsspor. Vi kender indretningen og overkommer
                    ikke lige, at skulle have den store projektblok frem, for at
                    få et helt nyt køkken.. Men hvis du er tilfreds med din
                    køkkenindretning, som det er nu, og du ikke påtænker at
                    flytte helt om på dit køkken... -
                  </p>
                  <p>Så er svaret JA!</p>
                  <p>
                    De fleste vælger at bibeholde, vask, komfur, opvaskemaskine
                    samt emhætte de samme steder, da tilslutningen af el og vand
                    samt aflød til vask og opvaskemaskine allerede er en fast
                    integration af køkkenet.
                  </p>
                  <p>
                    Så hvis du gerne vil have "nyt køkken" på 1 dag, uden
                    byggerod.
                  </p>
                </TextBlock>
              </Col>
            </Row>
          </Container>
        </MainSection>
      </Container>
    </MainLayout>
  )
}

export const query = graphql`
  {
    banner: file(relativePath: { eq: "banners/kitchen_black.jpg" }) {
      id
      childImageSharp {
        base64: sizes(base64Width: 1600, maxHeight: 800, quality: 100) {
          base64
        }
      }
    }
    imgOne: file(relativePath: { eq: "bil_1.jpg" }) {
      id
      childImageSharp {
        fluid(maxWidth: 250, maxHeight: 450) {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(height: 300, width: 600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    imgTwo: file(relativePath: { eq: "bil_2.jpg" }) {
      id
      childImageSharp {
        fluid(maxWidth: 250, maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(height: 300, width: 600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
