import React from "react"
import Styled from "styled-components"
import Image from "gatsby-image"

const StyledImageGrid = Styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 250px 100px;
  gap: 10px;

 .img-tile-grid:nth-child(2) {
  grid-column: 1;
  grid-row: 1/2;
}

.img-tile-grid:nth-child(1) {
  grid-column: 2;
  grid-row: 1/3;
}


`

const ImageGrid = ({ imageOne, imageTwo }) => {
  return (
    <StyledImageGrid>
      <Image className="img-tile-grid" fluid={imageOne}></Image>
      <Image className="img-tile-grid" fluid={imageTwo}></Image>
    </StyledImageGrid>
  )
}

export default ImageGrid
